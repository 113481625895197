import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Subtitle,
  ListContacts,
  DivButton,
  ButtonCancel,
  ButtonCadastro,
  NoContacts,
  XStyled,
} from './styles.js'
import * as DialogRadix from '@radix-ui/react-dialog'
import Input from '../../../../../../../../../../shared/components/Input/index.jsx'
import TagsService from '../../../../../../../../../../shared/services/Tags/TagsService.js'
import { XCircle } from 'phosphor-react'
import {
  Tag,
  TagAbbreviation,
  TagActions,
  TagText,
  TextAbbreviation,
} from '../../../../../../../../../Settings/components/MainScreen/components/Tags/styles.js'
import { useToast } from '../../../../../../../../../../hook/toast.jsx'
import { getTextColor } from '../../../../../../../../../../utils/format.js'

const DialogTag = ({
  id,
  open,
  onChangeOpen,
  showIconClose = true,
  tagsSelecteds,
  setTagsSelecteds,
  lateralContacts,
  setLateralContacts,
}) => {
  const [searchTags, setSearchTags] = useState('')
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const { addToast } = useToast()

  const handleSelectTag = (tag) => {
    const index = selectedTags.findIndex(
      (selectedTag) => selectedTag.id_tag === tag.id_tag,
    )
    if (index === -1) {
      setSelectedTags([...selectedTags, tag])
    } else {
      setSelectedTags(
        selectedTags.filter((selectedTag) => selectedTag.id_tag !== tag.id_tag),
      )
    }
  }

  const handleCloseDialog = () => {
    onChangeOpen(false)
    setSelectedTags([])
  }

  const filteredTags = () => {
    if (searchTags) {
      return tags.filter((tag) => {
        const name = tag?.name_tag?.toLowerCase()
        const abbreviation = tag?.abbreviation_tag?.toLowerCase()
        const search = searchTags.toLowerCase()
        if (tags.length === 0) return false
        return name.includes(search) || abbreviation.includes(search)
      })
    } else {
      return tags
    }
  }

  useEffect(() => {
    const getTags = async () => {
      const response = await TagsService.getTags()
      if (response.data) {
        setTags(
          response.data.filter(
            (tag) =>
              !tagsSelecteds.find(
                (userSelected) => userSelected.id_tag === tag.id_tag,
              ),
          ),
        )
      }
    }
    getTags()
  }, [tagsSelecteds])

  const verifySelectedTag = useCallback(
    (tag) => {
      return (
        selectedTags.findIndex(
          (selectedTag) => selectedTag.id_tag === tag.id_tag,
        ) !== -1
      )
    },
    [selectedTags],
  )

  const manipulateTags = (tags) => {
    if (!tags) {
      return [...selectedTags]
    }
    return [...tags, ...selectedTags]
  }

  const handleSave = async () => {
    const data = {
      userIDs: [id],
      tagsIDs: selectedTags.map((tag) => tag.id_tag),
    }
    const response = await TagsService.createContactTag(data)
    if (response.data) {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Contato adicionado com sucesso',
      })
      setSelectedTags([])
      setTagsSelecteds((prev) => [...prev, ...selectedTags])
      setLateralContacts((prev) =>
        prev.map((contact) => {
          if (contact.id === id) {
            return {
              ...contact,
              tags: manipulateTags(contact.tags),
            }
          }
          return contact
        }),
      )
      onChangeOpen(false)
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao adicionar contato',
      })
    }
  }

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogOverlay>
          <DialogRadix.Content asChild>
            <Container>
              <DialogContainer>
                <DialogRadix.Close asChild>
                  {showIconClose && (
                    <XStyled
                      onClick={handleCloseDialog}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </DialogRadix.Close>
                <Header>
                  <Title>Adicionar Tags</Title>
                  <Subtitle>Selecione as tags que deseja adicionar</Subtitle>
                </Header>
                <ListContacts>
                  <Input
                    placeholder="Pesquisar tag"
                    value={searchTags}
                    onChange={(e) => setSearchTags(e.target.value)}
                  />
                  {filteredTags().map((tag) => (
                    <Tag
                      key={tag.id_tag}
                      onClick={() => handleSelectTag(tag)}
                      $isSelected={verifySelectedTag(tag)}
                    >
                      <TagActions>
                        <TagText>{tag.name_tag}</TagText>
                        <TagText>-</TagText>
                        <TagAbbreviation $color={tag.color_hex_tag}>
                          <TextAbbreviation
                            $color={() => getTextColor(tag.color_hex_tag)}
                          >
                            {tag.abbreviation_tag}
                          </TextAbbreviation>
                        </TagAbbreviation>
                      </TagActions>
                      <TagActions>
                        {verifySelectedTag(tag) && <XCircle />}
                      </TagActions>
                    </Tag>
                  ))}
                  {filteredTags().length === 0 && (
                    <NoContacts>Nenhuma tag encontrada</NoContacts>
                  )}
                </ListContacts>
                <DivButton>
                  <ButtonCancel onClick={handleCloseDialog}>
                    Cancelar
                  </ButtonCancel>
                  <ButtonCadastro onClick={() => handleSave()}>
                    Adicionar
                  </ButtonCadastro>
                </DivButton>
              </DialogContainer>
            </Container>
          </DialogRadix.Content>
        </DialogOverlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default DialogTag
