import { useCallback, useEffect } from 'react'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  ErrorSpan,
} from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import TagsService from '../../../../../../../../shared/services/Tags/TagsService.js'
import { useToast } from '../../../../../../../../hook/toast.jsx'
import ColorPicker from './Components/ColorPicker/index.jsx'

const DialogTags = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  selectedTag,
  setSelectedTag,
}) => {
  const tagScheme = z.object({
    nameTag: z.string().min(1, 'Nome é obrigatório'),
    abbreviationTag: z.string().min(1, 'Abreviacao é obrigatório'),
    colorHexTag: z.string().min(1, 'Cor é obrigatório'),
  })

  const { addToast } = useToast()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(tagScheme),
  })

  useEffect(() => {
    if (selectedTag) {
      setValue('nameTag', selectedTag.name_tag)
      setValue('abbreviationTag', selectedTag.abbreviation_tag)
      setValue('colorHexTag', selectedTag.color_hex_tag)
    }
  }, [selectedTag, setValue])

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const onSubmit = async (data) => {
    if (selectedTag) {
      const response = await TagsService.updateTag(selectedTag.id_tag, data)
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Tag atualizada com sucesso!',
        })
        setSelectedTag(null)
        setUpdate(!update)
        onChangeOpen(false)
      } else {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Erro ao atualizar tag!',
        })
      }
    } else {
      const response = await TagsService.createTag(data)
      if (response.status === 201) {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Tag criada com sucesso!',
        })
        setUpdate(!update)
        onChangeOpen(false)
      } else {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Erro ao criar tag!',
        })
      }
    }
  }

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <Container>
                  <Header>
                    <Title>{selectedTag ? 'Editar Tag:' : 'Criar Tag:'}</Title>
                    <DialogRadix.Close asChild>
                      {showIconClose && (
                        <X
                          onClick={handleCloseDialog}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </DialogRadix.Close>
                  </Header>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Label>Nome da Tag:</Label>
                    <Input
                      type="text"
                      placeholder="Nome"
                      {...register('nameTag')}
                    />
                    <ErrorSpan $span={!!errors.nameTag?.message}>
                      {errors.nameTag?.message || 'a'}
                    </ErrorSpan>

                    <Label>Abreviação:</Label>
                    <Input
                      type="text"
                      placeholder="Abreviação"
                      {...register('abbreviationTag')}
                    />
                    <ErrorSpan $span={!!errors.abbreviationTag?.message}>
                      {errors.abbreviationTag?.message || 'a'}
                    </ErrorSpan>

                    <Label>Cor:</Label>
                    <ColorPicker
                      setValue={setValue}
                      name="colorHexTag"
                      value={selectedTag?.color_hex_tag}
                    />

                    <DivButton>
                      <Button onClick={handleCloseDialog}>Cancelar</Button>
                      <ButtonCadastro type="submit">
                        {selectedTag ? 'Atualizar' : 'Cadastrar'}
                      </ButtonCadastro>
                    </DivButton>
                  </Form>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default DialogTags
