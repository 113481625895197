import axios from 'axios'
import LocalStorage from '../../../utils/LocalStorage'
import { jwtDecode } from 'jwt-decode'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.response.use((response) => {
  if (response.config.url?.includes('user/login')) {
    LocalStorage.setValue('token', response.data.token)
  }
  return response
})

api.interceptors.request.use(
  async (config) => {
    if (config.url?.includes('user/login')) {
      return config
    }

    const token = LocalStorage.getValue('token')

    if (!token) {
      window.location.href = ''
    }

    const currentTime = Date.now() / 1000
    const decodedToken = jwtDecode(token)

    if (!!decodedToken.exp && decodedToken.exp < currentTime) {
      window.location.href = ''
      LocalStorage.clearAllValues()
    }

    config.headers.Authorization = 'Bearer ' + token

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
