import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
`

export const DivRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.DividerContact};
  border-right: 1px solid ${({ theme }) => theme.colors.DividerContact};
  border-top: 1px solid
    ${({ theme, $value }) =>
      $value === '1' ? theme.colors.DividerContact : 'transparent'};
  border-left: 1px solid ${({ theme }) => theme.colors.DividerContact};
  transition: background-color 0.3;
  background-color: ${({ theme, $tab, $value }) =>
    $tab === $value ? theme.colors.Primary : theme.colors.BackgroundMessage};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.WhiteHover};
  }
`

export const Title = styled.h2`
  font-size: 1.3rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.Text};
`
