import TagsApi from './api/TagsApi'

class TagsService {
  async getTags() {
    try {
      const response = await TagsApi.getTags()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createTag(data) {
    try {
      const response = await TagsApi.createTag(data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateTag(id, data) {
    try {
      const response = await TagsApi.updateTag(id, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteTag(id) {
    try {
      const response = await TagsApi.deleteTag(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createContactTag(data) {
    try {
      const response = await TagsApi.createContactTag(data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteContactTag(idContact, idTag) {
    try {
      const response = await TagsApi.deleteContactTag(idContact, idTag)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
}

export default new TagsService()
