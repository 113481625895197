import { api } from '../../api/axios'

export default new (class TagsApi {
  async getTags() {
    try {
      const response = await api.get('/tag')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createTag(data) {
    try {
      const response = await api.post('/tag', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateTag(id, data) {
    try {
      const response = await api.put(`/tag/${id}`, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteTag(id) {
    try {
      const response = await api.delete(`/tag/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createContactTag(data) {
    try {
      const response = await api.post('/contact-tag', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteContactTag(idContact, idTag) {
    try {
      const response = await api.delete(
        `/contact-tag/contact/${idContact}/tag/${idTag}`,
      )
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
