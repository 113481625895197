import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  ErrorSpan,
  Checkbox,
  CheckboxContainer,
  ModalImage,
  ModalImageContainer,
} from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import UserService from '../../../../../../../../shared/services/User/UserService.js'
import * as z from 'zod'
import { useToast } from '../../../../../../../../hook/toast.jsx'

const Dialog = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
}) => {
  const userScheme = z.object({
    fullNameUser: z.string().min(1, 'Nome é obrigatório'),
    emailUser: z.string().email('Email inválido').min(1, 'Email é obrigatório'),
    passwordUser: z.string().min(8, 'A senha deve ter no mínimo 8 caracteres'),
    isRootUser: z.boolean(),
  })

  const [image, setImage] = useState(null)
  const [error, setError] = useState('')

  const { addToast } = useToast()
  const [preview, setPreview] = useState(null)
  const fileInputRef = useRef(null)

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(userScheme),
  })

  useEffect(() => {
    if (!open) {
      reset()
      setImage(null)
      setError(null)
      setPreview(null)
    }
  }, [open, reset])

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const handleImageChange = (e) => {
    const file = e.target.files[0]

    const validTypes = ['image/jpeg', 'image/png', 'image/gif']
    const maxSize = 2 * 1024 * 1024 // 2MB

    if (file && !validTypes.includes(file.type)) {
      setError('Apenas imagens nos formatos JPEG, PNG ou GIF são permitidas.')
      setImage(null)
      return
    }

    if (file && file.size > maxSize) {
      setError('O tamanho da imagem não pode exceder 2MB.')
      setImage(null)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(file)
    setPreview(URL.createObjectURL(file))
    setError('')
  }

  const onSubmit = async (data) => {
    data = {
      ...data,
      userImage: image,
    }
    if (!error) {
      const response = await UserService.createUser(data)
      if (response.status === 201) {
        addToast({
          type: 'success',
          title: 'Usuário cadastrado com sucesso',
          description: 'O usuário foi cadastrado com sucesso',
        })
        setUpdate(!update)
        onChangeOpen(false)
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar usuário',
          description: 'Ocorreu um erro ao cadastrar o usuário',
        })
      }
    }
  }

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0]
  //   if (selectedFile) {
  //     setFile(selectedFile)
  //   }
  // }

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <Container>
                  <Header>
                    <Title>Criar usuário:</Title>
                    <DialogRadix.Close asChild>
                      {showIconClose && (
                        <X
                          onClick={handleCloseDialog}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </DialogRadix.Close>
                  </Header>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Label>Foto de usuário:</Label>
                    <Input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleImageChange}
                    />
                    <ErrorSpan $span={error}>{error || 'a'}</ErrorSpan>
                    {preview && (
                      <ModalImageContainer>
                        <ModalImage
                          src={preview}
                          alt="Preview da imagem selecionada"
                        />
                      </ModalImageContainer>
                    )}

                    <Label>Nome:</Label>
                    <Input
                      type="text"
                      placeholder="Nome"
                      {...register('fullNameUser')}
                    />
                    <ErrorSpan $span={!!errors.fullNameUser?.message}>
                      {errors.fullNameUser?.message || 'a'}
                    </ErrorSpan>

                    <Label>Email:</Label>
                    <Input
                      type="email"
                      placeholder="Email"
                      {...register('emailUser')}
                    />
                    <ErrorSpan $span={!!errors.emailUser?.message}>
                      {errors.emailUser?.message || 'a'}
                    </ErrorSpan>

                    <Label>Senha:</Label>
                    <Input
                      type="password"
                      placeholder="Senha"
                      {...register('passwordUser')}
                    />
                    <ErrorSpan $span={!!errors.passwordUser?.message}>
                      {errors.passwordUser?.message || 'a'}
                    </ErrorSpan>

                    <CheckboxContainer>
                      <Label>É Administrador?</Label>
                      <Checkbox type="checkbox" {...register('isRootUser')} />
                    </CheckboxContainer>
                    <ErrorSpan $span={!!errors.isRootUser?.message}>
                      {errors.isRootUser?.message || 'a'}
                    </ErrorSpan>

                    <DivButton>
                      <Button onClick={handleCloseDialog}>Cancelar</Button>
                      <ButtonCadastro type="submit">Cadastrar</ButtonCadastro>
                    </DivButton>
                  </Form>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default Dialog
