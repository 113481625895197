import styled from 'styled-components'

export const MyRoot = styled.div`
  display: flex;
  z-index: 0;
`

export const MyAccordionItem = styled.div`
  margin-top: 1px;
  width: ${(p) => p.$width || '100%'};
  margin-bottom: 1rem;

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:focus-within {
    position: relative;
    z-index: 1;
  }
`
export const MyHeader = styled.div`
  display: flex;
`

export const MyTrigger = styled.button`
  all: unset;
  padding: 0 5px 0 10px;
  height: 2.375rem;
  width: 13.1rem;
  justify-content: space-between;
  flex: 1;
  display: flex;
  align-items: center;
  > p {
    color: ${({ theme }) => theme.colors.Text};
  }
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
  color: #000;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.Primary};
  cursor: pointer;
  transition: border-radius 0.5s ease;

  .iconDown {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    height: 25px;
    width: 25px;
  }

  &[data-state='open'] {
    border-radius: 0.75rem 0.75rem 0 0;

    .iconDown {
      transform: rotate(180deg);
    }
    .open {
      border-radius: 0 0.75rem 0 0;
    }
  }
`

export const MyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.Primary};

  .noFoundCategory {
    display: flex;
    justify-content: center;
    color: red;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }

  &[data-state='open'] {
    animation: slideDown 400ms ease-in-out;
  }

  &[data-state='closed'] {
    animation: slideUp 100ms ease-in-out;
  }
`

export const MyIconSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  height: 100%;
  width: 40px;
  margin-right: -5px;
  border-left: 1px solid gray;
  background: ${({ theme }) => theme.colors.Button};
  border-radius: 0 0.75rem 0.75rem 0;
  transition: border-radius 0.5s ease;
`
