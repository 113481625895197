import React, { useEffect } from 'react'
import * as DialogRadix from '@radix-ui/react-dialog'
import {
  DialogContainer,
  DialogOverlay,
} from '../../../Tags/components/DialogTags/styles'
import {
  Title,
  Content,
  ListContent,
  ListContentTitle,
  ListContentText,
  Container,
  TextAreaStyled,
  Header,
  ButtonDownload,
} from './styles'
import {
  formatDateWithYear,
  formatTimeSchedule,
} from '../../../../../../../../utils/format'
import { X } from 'phosphor-react'
import FileService from '../../../../../../../../shared/services/File/FileService'
import {
  ContainerImgPreview,
  ImgPreview,
  VideoPreview,
} from '../DialogAddSchedules/styles'
import { AudioPlayerStyled } from '../../../../../../../Home/components/Chat/styles'

const DialogSchedule = ({
  open,
  onChangeOpen,
  schedule,
  setSchedule,
  typeMessageOptions,
  showIconClose = true,
}) => {
  const [mediaSrc, setMediaSrc] = React.useState(null)

  const getBase64Media = async (filename, filetype) => {
    try {
      const response = await FileService.getSrc(filename, filetype)
      setMediaSrc(response.data.base64)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (schedule?.file_name_message_schedule) {
      getBase64Media(
        schedule?.file_name_message_schedule,
        schedule?.file_type_message_schedule,
      )
    }
    // eslint-disable-next-line
  }, [schedule])

  const renderMsg = () => {
    if (schedule?.content_message_schedule) {
      return (
        <ListContent style={{ alignItems: 'start' }}>
          <ListContentTitle>Mensagem:</ListContentTitle>
          <TextAreaStyled value={schedule.content_message_schedule} readOnly />
        </ListContent>
      )
    }
  }

  const renderMedia = () => {
    if (schedule?.type_message_schedule === 'image') {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Imagem:
            </ListContentTitle>
            <ContainerImgPreview>
              <ImgPreview src={mediaSrc} />
            </ContainerImgPreview>
          </ListContent>
          {renderMsg()}
        </>
      )
    }

    if (
      schedule?.type_message_schedule === 'audio' ||
      schedule?.type_message_schedule === 'ptt'
    ) {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Áudio:
            </ListContentTitle>
            <AudioPlayerStyled src={mediaSrc} controls />
          </ListContent>
        </>
      )
    }

    if (schedule?.type_message_schedule === 'video') {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Vídeo:
            </ListContentTitle>
            <VideoPreview src={mediaSrc} controls />
          </ListContent>
          {renderMsg()}
        </>
      )
    }

    if (schedule?.type_message_schedule === 'document') {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Documento:
            </ListContentTitle>
            <ButtonDownload
              href={mediaSrc}
              target="_blank"
              rel="noreferrer"
              download={schedule?.file_name_message_schedule}
            >
              Baixar
            </ButtonDownload>
          </ListContent>
          {renderMsg()}
        </>
      )
    }

    return renderMsg()
  }

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <Container>
                  <Header>
                    <Title>Detalhes do agendamento:</Title>
                    {showIconClose && (
                      <X
                        style={{ cursor: 'pointer' }}
                        onClick={() => onChangeOpen(false)}
                      />
                    )}
                  </Header>
                  <Content>
                    <ListContent>
                      <ListContentTitle>Contato de destino:</ListContentTitle>
                      <ListContentText>
                        {schedule?.to_contact_message_schedule.split('@')[0]}
                      </ListContentText>
                    </ListContent>
                    <ListContent>
                      <ListContentTitle>Tipo de mensagem:</ListContentTitle>
                      <ListContentText>
                        {typeMessageOptions[schedule?.type_message_schedule]}
                      </ListContentText>
                    </ListContent>
                    <ListContent>
                      <ListContentTitle>Status:</ListContentTitle>
                      <ListContentText>
                        {schedule?.is_processed_schedule
                          ? 'Enviado'
                          : 'Pendente'}
                      </ListContentText>
                    </ListContent>
                    <ListContent>
                      <ListContentTitle>Hora:</ListContentTitle>
                      <ListContentText>
                        {formatTimeSchedule(schedule?.timestamp_schedule)}
                      </ListContentText>
                      <ListContentTitle>Data:</ListContentTitle>
                      <ListContentText>
                        {formatDateWithYear(schedule?.timestamp_schedule)}
                      </ListContentText>
                    </ListContent>
                    {renderMedia()}
                  </Content>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default DialogSchedule
