export default new (class LocalStorage {
  setValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  getValue(key) {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  removeValue(key) {
    localStorage.removeItem(key)
  }

  getTheme() {
    const theme = this.getValue('theme')

    if (theme === 'light') {
      return 'Claro'
    }
    if (theme === 'dark') {
      return 'Escuro'
    }

    return 'Claro'
  }

  setEmojiStyle(value) {
    this.setValue('emojiStyle', value)
  }

  getEmojiStyle() {
    const emojiStyle = this.getValue('emojiStyle')

    return emojiStyle || 'native'
  }

  clearAllValues() {
    localStorage.clear()
  }
})()
