import React, { useState } from 'react'
import { Container, InputColor, PColor } from './styles'

function ColorPicker({ setValue, name, value }) {
  const [color, setColor] = useState(value || '#000000')

  const handleColorChange = (e) => {
    setColor(e.target.value)
    setValue(name, e.target.value)
  }

  return (
    <Container>
      <InputColor
        type="color"
        id="color"
        value={color}
        onChange={handleColorChange}
      />
      <PColor>Cor selecionada: {color}</PColor>
    </Container>
  )
}

export default ColorPicker
