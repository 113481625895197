import { useEffect, useState } from 'react'
import {
  Main,
  DateBox,
  Container,
  ImageMine,
  ImageNotMine,
  IconNotMine,
  IconMine,
  ContainerMessage,
  DownloadMessage,
  TimeDifference,
  TooltipContent,
  TooltipArrow,
} from './styles'
import {
  formatTimeSent,
  formatDate,
  extractImage,
} from '../../../../../../utils/format'
import * as Tooltip from '@radix-ui/react-tooltip'
import imageContato from '../../../../../../assets/images/imageContato'
import UserService from '../../../../../../shared/services/User/UserService'
import FileService from '../../../../../../shared/services/File/FileService'
import { useImage } from '../../../../../../hook/image'
const Message = ({ message, dataContact }) => {
  const [mediaSrc, setMediaSrc] = useState(null)
  const [mediaClicked, setMediaClicked] = useState(false)
  const { contactImage, setUserImage, userImage } = useImage()
  const getUserImage = async (id) => {
    return await UserService.getUserById(id)
  }

  useEffect(() => {
    const loadImage = async () => {
      if (!userImage[message.senderUserId]) {
        try {
          const res = await getUserImage(message.senderUserId)
          if (
            res.data.picture_filename_user &&
            res.data.picture_filetype_user
          ) {
            const base64 = await getBase64Media(
              res.data.picture_filename_user,
              res.data.picture_filetype_user,
            )
            setUserImage(message.senderUserId, base64)
          }
        } catch (error) {
          console.log('Error loading image', error)
        }
      }
    }
    if (message.senderUserId) {
      loadImage()
    }
    // eslint-disable-next-line
  }, [message.senderUserId])
  const getBase64Media = async (fileName, fileType) => {
    try {
      const response = await FileService.getSrc(fileName, fileType)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (mediaClicked && message.fileName && message.fileType) {
      getBase64Media(message.fileName, message.fileType)
        .then((res) => {
          setMediaSrc(res)
        })
        .catch((err) => {
          console.error('Error fetching media:', err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaClicked, message.fileName, message.fileType])

  const handleMediaClick = () => {
    setMediaClicked(true)
  }

  const renderMedia = () => {
    if (!mediaSrc && message.fileType === 'data:image/jpeg;base64') {
      return <p>Clique para carregar imagem</p>
    } else if (!mediaSrc && message.fileType === 'data:video/mp4;base64') {
      return <p>Clique para carregar vídeo</p>
    } else if (!mediaSrc && message.fileType.startsWith('data:audio')) {
      return <p>Clique para carregar áudio</p>
    } else if (!mediaSrc) {
      return <p>Clique para carregar arquivo</p>
    }
    if (message.fileType.startsWith('data:image')) {
      return (
        <img
          src={mediaSrc}
          alt="media message"
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
      )
    }
    if (message.fileType.startsWith('data:video')) {
      return (
        <video
          src={mediaSrc}
          controls
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
      )
    }
    if (message.fileType.startsWith('data:audio')) {
      return (
        <audio
          src={mediaSrc}
          controls
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
      )
    }
    return (
      <div className="media-message">
        <a href={mediaSrc} download={message.fileName}>
          Fazer Download do arquivo
        </a>
      </div>
    )
  }

  const makeLinksClickable = (text) => {
    const urlRegex =
      /\b((https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)\b/g
    const processedText = text.replace(urlRegex, (url) => {
      const href = url.startsWith('http') ? url : `http://${url}`
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`
    })

    return <span dangerouslySetInnerHTML={{ __html: processedText }} />
  }

  return (
    <Main>
      <DateBox $isFlag={message.isFlag}>
        <p>{formatDate(message.date)}</p>
      </DateBox>
      <Container $isFlag={message.isFlag} $isMine={message.isMine}>
        {extractImage(contactImage[dataContact.id]) !== 'null' ? (
          <ImageNotMine
            $isMine={message.isMine}
            src={contactImage[dataContact.id]}
            alt="Imagem do contato"
          />
        ) : (
          <ImageNotMine
            $isMine={message.isMine}
            src={imageContato}
            alt="Imagem do contato"
          />
        )}
        <IconNotMine $isMine={message.isMine} />
        <ContainerMessage
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        >
          {message.fileType &&
            (message.fileType.startsWith('image/') ||
              message.fileType.startsWith('video/') ||
              message.fileType.startsWith('audio/') ||
              message.fileType) && (
              <DownloadMessage
                className="media-message"
                onClick={handleMediaClick}
              >
                {renderMedia()}
              </DownloadMessage>
            )}
          {message.message && <p>{makeLinksClickable(message.message)}</p>}
          <TimeDifference>
            {formatTimeSent(message.sendDateMessage)}
          </TimeDifference>
        </ContainerMessage>
        <IconMine
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        />
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <ImageMine
                $isMine={message.isMine}
                src={userImage[message.senderUserId] || imageContato}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <TooltipContent className="TooltipContent" sideOffset={5}>
                {message.userName ? (
                  <p>Enviado por {message.userName}</p>
                ) : (
                  <p>Impossível determinar autor</p>
                )}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </Container>
    </Main>
  )
}

export default Message
