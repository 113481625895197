import React, { useState, createContext, useContext } from 'react'

const ImageContext = createContext()

export const ImageProvider = ({ children }) => {
  const [contactImage, setContactImage] = useState([])
  const [userImage, setUserImage] = useState([])

  const updateContactImage = (id, image) => {
    setContactImage((prevImages) => ({
      ...prevImages,
      [id]: image,
    }))
  }

  const updateUserImage = (id, image) => {
    setUserImage((prevImages) => ({
      ...prevImages,
      [id]: image,
    }))
  }
  return (
    <ImageContext.Provider
      value={{
        contactImage,
        setContactImage: updateContactImage,
        userImage,
        setUserImage: updateUserImage,
      }}
    >
      {children}
    </ImageContext.Provider>
  )
}

export const useImage = () => {
  const context = useContext(ImageContext)
  if (!context) {
    throw new Error('useImageContext must be used within an ImageProvider')
  }
  return context
}
