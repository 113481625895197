import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  ErrorSpan,
  Checkbox,
  CheckboxContainer,
  ModalImage,
  ModalImageContainer,
} from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import UserService from '../../../../../../../../shared/services/User/UserService.js'
import { useToast } from '../../../../../../../../hook/toast.jsx'
import FileService from '../../../../../../../../shared/services/File/FileService.js'
const Dialog = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  user,
  socket,
}) => {
  const { addToast } = useToast()
  // const MAX_FILE_SIZE = 120 * 1024 * 1024
  // const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']
  const userScheme = z.object({
    fullNameUser: z.string().min(1, 'Nome é obrigatório'),
    emailUser: z.string().email('Email inválido').min(1, 'Email é obrigatório'),
    passwordUser: z.string().min(8, 'A senha deve ter no mínimo 8 caracteres'),
    isRootUser: z.boolean(),
    userImage: z.any(),
  })

  const [file, setFile] = useState(null)
  const [imgFlag, setImgFlag] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    if (file) {
      const previewUrl = URL.createObjectURL(file)
      setImagePreview(previewUrl)
    } else {
      setImagePreview(null)
    }

    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(userScheme),
  })

  const getBase64 = async (filename, filetype) => {
    if (!filename || !filetype) {
      return
    }
    try {
      const response = await FileService.getSrc(filename, filetype)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const initializeUser = async () => {
    const img = await getBase64(
      user.picture_filename_user,
      user.picture_filetype_user,
    )
    setValue('fullNameUser', user.full_name_user)
    setValue('emailUser', user.email_user)
    setValue('passwordUser', user.password_user)
    setValue('isRootUser', user.is_root_user)
    setValue('userImage', img)
    setImagePreview(img)
  }

  useEffect(() => {
    if (user) {
      initializeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setValue])

  useEffect(() => {
    if (!open) {
      reset()
      setFile(null)
      setImagePreview(null)
    }
  }, [open, reset])

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const onSubmit = async (data) => {
    const image = data.userImage[0]
    delete data.userImage
    const res = await UserService.updateUser(user.id_user, data)
    if (res.status === 200) {
      if (image && imgFlag) {
        // logica da imagem
        console.log('entrou')
        let base64 = await fileToBase64(image)
        base64 = base64.split(',')[1]
        await UserService.updateImage(user.id_user, base64)
      }
      addToast({
        type: 'success',
        title: 'Usuário editado com sucesso',
        description: 'O usuário foi editado com sucesso',
      })
      setUpdate(!update)
      handleCloseDialog()
    }
  }

  if (user) {
    return (
      <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
        <DialogRadix.Portal>
          <DialogRadix.Overlay asChild>
            <DialogOverlay>
              <DialogRadix.Content asChild>
                <DialogContainer>
                  <Container>
                    <Header>
                      <Title>Editar usuário:</Title>
                      <DialogRadix.Close asChild>
                        {showIconClose && (
                          <X
                            onClick={handleCloseDialog}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </DialogRadix.Close>
                    </Header>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Label>Foto de usuário:</Label>
                      <Input
                        type="file"
                        ref={fileInputRef}
                        {...register('userImage')}
                        onChange={(event) => {
                          const selectedFile = event.target.files[0]
                          if (selectedFile) {
                            setImgFlag(true)
                            setFile(selectedFile)
                          }
                        }}
                      />
                      <ErrorSpan $span={!!errors.userImage?.message}>
                        {errors.userImage?.message}
                      </ErrorSpan>
                      {imagePreview && (
                        <ModalImageContainer>
                          <ModalImage
                            src={imagePreview}
                            alt="Preview da imagem selecionada"
                          />
                        </ModalImageContainer>
                      )}
                      <Label>Nome:</Label>
                      <Input
                        type="text"
                        placeholder="Nome"
                        {...register('fullNameUser')}
                      />
                      <ErrorSpan $span={!!errors.fullNameUser?.message}>
                        {errors.fullNameUser?.message}
                      </ErrorSpan>
                      <Label>Email:</Label>
                      <Input
                        type="email"
                        placeholder="Email"
                        {...register('emailUser')}
                      />
                      <ErrorSpan $span={!!errors.emailUser?.message}>
                        {errors.emailUser?.message}
                      </ErrorSpan>
                      <Label>Senha:</Label>
                      <Input
                        type="password"
                        placeholder="Senha"
                        {...register('passwordUser')}
                      />
                      <ErrorSpan $span={!!errors.passwordUser?.message}>
                        {errors.passwordUser?.message}
                      </ErrorSpan>
                      <CheckboxContainer>
                        <Label>É Administrador?</Label>
                        <Checkbox type="checkbox" {...register('isRootUser')} />
                      </CheckboxContainer>
                      <ErrorSpan $span={!!errors.isRootUser?.message}>
                        {errors.isRootUser?.message}
                      </ErrorSpan>
                      <DivButton>
                        <Button onClick={handleCloseDialog}>Cancelar</Button>
                        <ButtonCadastro type="submit">Editar</ButtonCadastro>
                      </DivButton>
                    </Form>
                  </Container>
                </DialogContainer>
              </DialogRadix.Content>
            </DialogOverlay>
          </DialogRadix.Overlay>
        </DialogRadix.Portal>
      </DialogRadix.Root>
    )
  }
}

export default Dialog
