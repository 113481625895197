import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      {...props}
    >
      <path d="M9 16L.34.25h17.32L9 16z" />
    </svg>
  )
}

export default SvgComponent
