import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Subtitle,
  ListContacts,
  Contact,
  ContactText,
  DivRowContact,
  DivButton,
  ButtonCancel,
  ButtonCadastro,
  NoContacts,
  XStyled,
} from './styles.js'
import { NameEmailSection, Email } from '../../styles.js'
import * as DialogRadix from '@radix-ui/react-dialog'
import Input from '../../../../../../../../../../shared/components/Input/index.jsx'
import ContactService from '../../../../../../../../../../shared/services/Contact/ContactService.js'
import UserService from '../../../../../../../../../../shared/services/User/UserService.js'
import { XCircle } from 'phosphor-react'
import { useToast } from '../../../../../../../../../../hook/toast.jsx'

const Dialog = ({
  id,
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  usersSelecteds,
}) => {
  const [searchContact, setSearchContact] = useState('')
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const { addToast } = useToast()

  const handleSelectUser = (user) => {
    const index = selectedUsers.findIndex(
      (selectedUser) => selectedUser.id_user === user.id_user,
    )
    if (index === -1) {
      setSelectedUsers([...selectedUsers, user])
    } else {
      setSelectedUsers(
        selectedUsers.filter(
          (selectedUser) => selectedUser.id_user !== user.id_user,
        ),
      )
    }
  }

  const handleCloseDialog = () => {
    onChangeOpen(false)
    setSelectedUsers([])
  }

  const filteredContactsByNameOrNumber = () => {
    if (searchContact) {
      return users.filter((user) => {
        const email = user?.email_user?.toLowerCase()
        const name = user?.full_name_user?.toLowerCase()
        const search = searchContact.toLowerCase()
        if (users.length === 0) return false
        return name.includes(search) || email.includes(search)
      })
    } else {
      return users
    }
  }

  useEffect(() => {
    const getUsers = async () => {
      const response = await UserService.getUsers()
      if (response.data) {
        setUsers(
          response.data.filter(
            (user) =>
              !usersSelecteds.find(
                (userSelected) => userSelected.id_user === user.id_user,
              ),
          ),
        )
      }
    }
    getUsers()
  }, [usersSelecteds])

  const verifySelectedUser = useCallback(
    (user) => {
      return (
        selectedUsers.findIndex(
          (selectedUser) => selectedUser.id_user === user.id_user,
        ) !== -1
      )
    },
    [selectedUsers],
  )

  const handleSave = async () => {
    const data = {
      IDsUserUsersContacts: selectedUsers.map((user) => user.id_user),
      IDsContactUsersContacts: [id],
    }
    const response = await ContactService.createUserContact(data)
    if (response.status === 201) {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Usuário adicionado com sucesso',
      })
      setUpdate(!update)
      setSelectedUsers([])
      onChangeOpen(false)
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao adicionar usuário',
      })
    }
  }

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogOverlay>
          <DialogRadix.Content asChild>
            <Container>
              <DialogContainer>
                <DialogRadix.Close asChild>
                  {showIconClose && (
                    <XStyled
                      onClick={handleCloseDialog}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </DialogRadix.Close>
                <Header>
                  <Title>Adicionar Usuários</Title>
                  <Subtitle>
                    Selecione os usuários que deseja adicionar
                  </Subtitle>
                </Header>
                <ListContacts>
                  <Input
                    placeholder="Pesquisar usuário"
                    value={searchContact}
                    onChange={(e) => setSearchContact(e.target.value)}
                  />
                  {filteredContactsByNameOrNumber().map((user) => (
                    <Contact
                      key={user.id_user}
                      onClick={() => handleSelectUser(user)}
                      $isSelected={verifySelectedUser(user)}
                    >
                      <DivRowContact>
                        <NameEmailSection>
                          <ContactText>{user.full_name_user}</ContactText>
                          <Email>{user.email_user}</Email>
                        </NameEmailSection>
                        {verifySelectedUser(user) && <XCircle />}
                      </DivRowContact>
                    </Contact>
                  ))}
                  {filteredContactsByNameOrNumber().length === 0 && (
                    <NoContacts>Nenhum usuário encontrado</NoContacts>
                  )}
                </ListContacts>
                <DivButton>
                  <ButtonCancel onClick={handleCloseDialog}>
                    Cancelar
                  </ButtonCancel>
                  <ButtonCadastro onClick={handleSave}>
                    Adicionar
                  </ButtonCadastro>
                </DivButton>
              </DialogContainer>
            </Container>
          </DialogRadix.Content>
        </DialogOverlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default Dialog
