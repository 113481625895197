import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

const LoadingSpinner = (props) => {
  return (
    <RotatingLines
      visible={true}
      height="96"
      width="96"
      strokeWidth="5"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      wrapperStyle={{}}
      wrapperClass=""
      {...props}
    />
  )
}

export default LoadingSpinner
