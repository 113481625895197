import FileApi from './api/FileApi'

class FileService {
  async getSrc(filename, filetype) {
    try {
      const response = await FileApi.getBase64(filename, filetype)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
}

export default new FileService()
