import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 2rem;
  width: 100%;
`

export const DialogContainer = styled.div`
  background-color: white;
  padding: 1.5rem 1rem 1rem 1rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 35rem;
  color: ${({ theme }) => theme.colors.Text};
  background-color: ${({ theme }) => theme.colors.MainScreen};
  max-height: 90vh;
  overflow-y: auto;

  > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  @media (max-width: 768px) {
    width: 90vw;
  }
`

export const DialogOverlay = styled.div`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 1000;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Label = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`

export const ButtonCadastro = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const ErrorSpan = styled.span`
  color: ${(props) => (props.$span ? 'red' : 'transparent')};
  font-size: 0.8rem;
  margin-top: -0.8rem;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
    border-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  }

  &:checked::after {
    content: '\\2714';
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.Text};
  }
`

export const ModalImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalImage = styled.img`
  src: ${(props) => props.src};
  alt: ${(props) => props.alt};
  max-width: 50%;
  align-self: center;
`
