import React, { forwardRef } from 'react'
import { Container } from './styles'

// eslint-disable-next-line react/display-name
const Input = forwardRef(({ type, placeholder, ...props }, ref) => {
  return (
    <Container>
      <input type={type} placeholder={placeholder} ref={ref} {...props} />
    </Container>
  )
})

export default Input
