import { api } from '../../api/axios'

export default new (class ScheduleApi {
  async getSchedules() {
    try {
      const response = await api.get('/schedules')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createSchedule(data) {
    try {
      const response = await api.post('/schedules', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateSchedule(id, data) {
    try {
      const response = await api.put(`/schedules/${id}`, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteSchedule(id) {
    try {
      const response = await api.delete(`/schedules/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
