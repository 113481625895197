import React from 'react'
import { Container, Title, DivRow } from './styles'

const TabsMenu = ({ tab, setTab, options, setNavigation }) => {
  return (
    <Container>
      {options.map((option, index) => (
        <DivRow
          key={index}
          onClick={() => {
            setTab(option.value)
            setNavigation('configScreen')
          }}
          $value={option.value}
          $tab={tab}
        >
          <Title>{option.label}</Title>
        </DivRow>
      ))}
    </Container>
  )
}

export default TabsMenu
