import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  DivForm,
  DivDate,
  DivInput,
  ImgPreview,
  ContainerImgPreview,
  VideoPreview,
} from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
// import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import {
  Root as SelectRoot,
  Value as SelectValue,
  ItemText as SelectItemText,
  Icon as SelectIcon,
} from '@radix-ui/react-select'
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '../../../Geral/styles.js'
// import { useForm } from 'react-hook-form'
// import { zodResolver } from '@hookform/resolvers/zod'
// import * as z from 'zod'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import DropdownList from '../../../../../../../../shared/components/DropdownList/index.jsx'
import ScheduleService from '../../../../../../../../shared/services/Schedule/ScheduleService.js'
import ContactService from '../../../../../../../../shared/services/Contact/ContactService.js'
import { useSession } from '../../../../../../../../hook/session.jsx'
import {
  AudioPlayerStyled,
  ContainerActions,
  ContainerPreview,
  ContainerRec,
  MicrophoneStyled,
  RecTime,
} from '../../../../../../../Home/components/Chat/styles.js'
import {
  formatTime,
  // formatTimeSchedule,
  getFileType,
  // formatDate2,
} from '../../../../../../../../utils/format.js'
import { StyledTrash } from '../../../Gerenciamento/styles.js'
import { useToast } from '../../../../../../../../hook/toast.jsx'
import { ErrorsContainer, ErrorSpan } from '../../../AudioLibraryStore/style.js'

const DialogAddSchedules = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  // selectedSchedule,
  // setSelectedSchedule,
  typeMessageOptions,
}) => {
  const { addToast } = useToast()
  const { getSessionData } = useSession()
  const [typeMessage, setTypeMessage] = useState('selecione')
  const [contacts, setContacts] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState(null)
  const recordingInterval = useRef(null)
  const [file, setFile] = useState(null)
  const [audioBlob, setAudioBlob] = useState(null)
  const [recordingTime, setRecordingTime] = useState(0)
  const [contentMessage, setContentMessage] = useState('')
  const [errors, setErrors] = useState(null)

  // useEffect(() => {
  //   if (selectedSchedule) {
  //     console.log('selected', selectedSchedule)
  //     setSelectedContact(selectedSchedule.to_contact_message_schedule)
  //     setTypeMessage(selectedSchedule.type_message_schedule)
  //     setDate(formatDate2(selectedSchedule.timestamp_schedule))
  //     setTime(formatTimeSchedule(selectedSchedule.timestamp_schedule))
  //     setFile(selectedSchedule.base64_file)
  //     setContentMessage(selectedSchedule.content_message_schedule)
  //   }
  // }, [selectedSchedule])

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const validateForm = () => {
    const newErrors = {}
    if (!selectedContact) {
      newErrors.selectedContact = 'Selecione um contato'
    }
    if (!date) {
      newErrors.date = 'Selecione uma data'
    }
    if (!time) {
      newErrors.time = 'Selecione um horário'
    }
    if (typeMessage === 'selecione') {
      newErrors.typeMessage = 'Selecione um tipo de mensagem'
    }
    if (typeMessage === 'chat' && !contentMessage) {
      newErrors.contentMessage = 'Digite uma mensagem'
    }
    if (typeMessage === 'image' && !file) {
      newErrors.file = 'Selecione uma imagem'
    }
    if (typeMessage === 'ptt' && !file) {
      newErrors.file = 'Grave um áudio'
    }
    if (typeMessage === 'audio' && !file) {
      newErrors.file = 'Selecione um áudio'
    }
    if (typeMessage === 'video' && !file) {
      newErrors.file = 'Selecione um vídeo'
    }
    if (typeMessage === 'document' && !file) {
      newErrors.file = 'Selecione um documento'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleDateChange = (e) => {
    setDate(e.target.value)
  }

  const handleTimeChange = (e) => {
    setTime(e.target.value)
  }

  const getTimestamp = () => {
    if (!date || !time) return ''
    const newDate = new Date(`${date}T${time}`)
    const formattedDate = newDate.toISOString().slice(0, 19)
    return formattedDate
  }

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const handleChange = (value) => {
    setTypeMessage(value)
    setFile(null)
  }

  useEffect(() => {
    if (audioBlob) {
      // const audio = new File([audioBlob], 'audioMessage.webm', {
      //   type: 'audio/webm',
      // })
      // transforma o audio em blob mp3
      const audio = new File([audioBlob], 'audioMessage.mp3', {
        type: 'audio/mp3',
      })
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64Audio = reader.result
        setFile(base64Audio)
      }

      reader.readAsDataURL(audio)
    }
  }, [audioBlob])

  useEffect(() => {
    if (!open) {
      // setSelectedSchedule(null)
      setTypeMessage('selecione')
      setSelectedContact(null)
      setDate('')
      setTime('')
      setIsRecording(false)
      setMediaRecorder(null)
      clearInterval(recordingInterval.current)
      setFile(null)
      setRecordingTime(0)
      setAudioBlob(null)
      setContentMessage('')
    }

    if (open) {
      const getContacts = async () => {
        const response = await ContactService.getUserContactsByUser(
          getSessionData().id,
        )
        setContacts(
          response.data.map((contact) => ({
            value: contact.id_contact,
            label: contact.full_name_contact,
          })),
        )
      }
      getContacts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  //   const onSubmit = async (data) => {
  //     console.log('data', data)
  //   }

  const stopRecording = () => {
    mediaRecorder.stop()
    setIsRecording(false)
    clearInterval(recordingInterval.current)
  }

  const startRecording = () => {
    setFile(null)
    setRecordingTime(0)
    try {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream)
        setMediaRecorder(mediaRecorder)
        mediaRecorder.start()
        setIsRecording(true)

        recordingInterval.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1)
        }, 1000)

        mediaRecorder.ondataavailable = (e) => {
          setAudioBlob(e.data)
        }
      })
    } catch (err) {
      console.log('Error recording audio', err)
    }
  }

  const renderForm = () => {
    if (typeMessage === 'chat') {
      return (
        <DivForm>
          <Label>Chat:</Label>
          <Input
            type="text"
            placeholder="Digite a mensagem"
            value={contentMessage}
            onChange={(e) => setContentMessage(e.target.value)}
          />
        </DivForm>
      )
    }

    if (typeMessage === 'image') {
      return (
        <DivForm>
          <Label>Mensagem:</Label>
          <Input
            type="text"
            placeholder="Digite a mensagem"
            value={contentMessage}
            onChange={(e) => setContentMessage(e.target.value)}
          />
          <Label>Imagem:</Label>
          <Input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0]
              toBase64(file).then((res) => {
                setFile(res)
              })
            }}
          />
          {getFileType(file) === 'image' && (
            <ContainerImgPreview>
              <ImgPreview src={file} alt="preview" style={{ width: '100%' }} />
            </ContainerImgPreview>
          )}
        </DivForm>
      )
    }

    if (typeMessage === 'ptt') {
      return (
        <DivForm>
          <Label>Clique no microfone para gravar:</Label>
          <ContainerRec style={{ padding: '1rem' }}>
            <MicrophoneStyled
              onClick={() => {
                if (isRecording) {
                  stopRecording()
                } else {
                  startRecording()
                }
              }}
              $isRecording={isRecording}
            />
            <RecTime $isRecording={isRecording}>
              {formatTime(recordingTime)}
            </RecTime>
          </ContainerRec>
          {(getFileType(file) === 'ptt' || getFileType(file) === 'audio') && (
            <ContainerPreview>
              <AudioPlayerStyled controls src={file} />
              <ContainerActions>
                <StyledTrash
                  onClick={() => {
                    setFile(null)
                  }}
                />
              </ContainerActions>
            </ContainerPreview>
          )}
        </DivForm>
      )
    }

    if (typeMessage === 'audio') {
      return (
        <DivForm>
          <Label>Áudio:</Label>
          <Input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0]
              toBase64(file).then((res) => {
                setFile(res)
              })
            }}
          />
          {getFileType(file) === 'audio' && (
            <ContainerImgPreview>
              <AudioPlayerStyled controls src={file} />
            </ContainerImgPreview>
          )}
        </DivForm>
      )
    }

    if (typeMessage === 'video') {
      return (
        <DivForm>
          <Label>Mensagem:</Label>
          <Input
            type="text"
            placeholder="Digite a mensagem"
            value={contentMessage}
            onChange={(e) => setContentMessage(e.target.value)}
          />
          <Label>Vídeo:</Label>
          <Input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0]
              toBase64(file).then((res) => {
                setFile(res)
              })
            }}
          />
          {getFileType(file) === 'video' && (
            <ContainerImgPreview>
              <VideoPreview
                src={file}
                controls
                style={{ width: '100%', height: 'auto' }}
              />
            </ContainerImgPreview>
          )}
        </DivForm>
      )
    }

    if (typeMessage === 'document') {
      return (
        <DivForm>
          <Label>Mensagem:</Label>
          <Input
            type="text"
            placeholder="Digite a mensagem"
            value={contentMessage}
            onChange={(e) => setContentMessage(e.target.value)}
          />
          <Label>Documento:</Label>
          <Input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0]
              toBase64(file).then((res) => {
                setFile(res)
              })
            }}
          />
        </DivForm>
      )
    }
  }

  // const selectContact = () => {
  //   if (typeMessage !== 'selecione') {
  //     return (
  //       <DivForm>
  //         <Label>Contato</Label>
  //         <Input type="text" placeholder="Digite o contato" />
  //       </DivForm>
  //     )
  //   }
  // }

  const getData = (e) => {
    const data = {
      type_message_schedule: typeMessage,
      to_contact_message_schedule: selectedContact,
      timestamp_schedule: getTimestamp(),
    }
    if (typeMessage === 'chat') {
      return {
        ...data,
        content_message_schedule: e.target[5].value,
      }
    }

    if (typeMessage === 'ptt' || typeMessage === 'audio') {
      return {
        ...data,
        content_message_schedule: '\nMensagem de Áudio',
        base64_file: file,
      }
    }

    if (
      typeMessage === 'image' ||
      typeMessage === 'video' ||
      typeMessage === 'document'
    ) {
      return {
        ...data,
        content_message_schedule: e.target[5].value,
        base64_file: file,
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validateForm()) {
      return null
    }
    const data = getData(e)
    // if (selectedSchedule) {
    //   const res = await ScheduleService.updateSchedule(
    //     selectedSchedule.id_schedule,
    //     data,
    //   )
    //   if (res.status === 200) {
    //     addToast({
    //       type: 'success',
    //       title: 'Sucesso!',
    //       description: 'O agendamento foi atualizado com sucesso',
    //     })
    //     setUpdate(!update)
    //     onChangeOpen(false)
    //   } else {
    //     addToast({
    //       type: 'error',
    //       title: 'Erro!',
    //       description: 'Ocorreu um erro ao atualizar o agendamento',
    //     })
    //   }
    // } else {
    const res = await ScheduleService.createSchedule(data)
    if (res.status === 201) {
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'O agendamento foi criado com sucesso',
      })
      setUpdate(!update)
      onChangeOpen(false)
    } else {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Ocorreu um erro ao criar o agendamento',
      })
    }
  }

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <Container>
                  <Header>
                    <Title>Criar Agendamento:</Title>
                    <DialogRadix.Close asChild>
                      {showIconClose && (
                        <X
                          onClick={handleCloseDialog}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </DialogRadix.Close>
                  </Header>
                  <Form onSubmit={handleSubmit}>
                    <Label>Contato:</Label>
                    <DropdownList
                      items={contacts}
                      $label="Selecione o contato"
                      handleClick={() => {}}
                      name={'contato'}
                      $onBlur={() => {}}
                      selectedValue={selectedContact}
                      setSelectedValue={setSelectedContact}
                    />
                    <DivDate>
                      <DivInput>
                        <Label>Horário:</Label>
                        <Input
                          type="time"
                          value={time}
                          onChange={handleTimeChange}
                          placeholder="Selecione a hora"
                          style={{
                            padding: '8px',
                            borderRadius: '4px',
                          }}
                        />
                      </DivInput>
                      <DivInput>
                        <Label>Data:</Label>
                        <Input
                          type="date"
                          value={date}
                          onChange={handleDateChange}
                          placeholder="Selecione a data"
                          style={{
                            padding: '8px',
                            marginRight: '8px',
                            borderRadius: '4px',
                          }}
                        />
                      </DivInput>
                      <DivInput $isMobile={window.width > 768}>
                        <Label>Tipo da Mensagem:</Label>
                        <SelectRoot onValueChange={handleChange}>
                          <SelectTrigger
                            style={{
                              wordBreak: 'nowrap',
                            }}
                          >
                            <SelectValue
                              style={{ whiteSpace: 'nowrap' }}
                              placeholder={typeMessageOptions[typeMessage]}
                            />
                            <SelectIcon />
                          </SelectTrigger>
                          <SelectContent
                            style={{
                              marginLeft: '0',
                            }}
                          >
                            <SelectItem value="chat">
                              <SelectItemText>Chat</SelectItemText>
                            </SelectItem>
                            <SelectItem value="image">
                              <SelectItemText>Imagem</SelectItemText>
                            </SelectItem>
                            <SelectItem value="ptt">
                              <SelectItemText>Audio Gravado</SelectItemText>
                            </SelectItem>
                            <SelectItem value="audio">
                              <SelectItemText>Áudio</SelectItemText>
                            </SelectItem>
                            <SelectItem value="video">
                              <SelectItemText>Vídeo</SelectItemText>
                            </SelectItem>
                            <SelectItem value="document">
                              <SelectItemText>Documento</SelectItemText>
                            </SelectItem>
                          </SelectContent>
                        </SelectRoot>
                      </DivInput>
                    </DivDate>
                    {renderForm()}
                    {errors && (
                      <ErrorsContainer>
                        {Object.values(errors).map((error) => (
                          <ErrorSpan key={error}>{error}</ErrorSpan>
                        ))}
                      </ErrorsContainer>
                    )}
                    <DivButton>
                      <Button onClick={handleCloseDialog}>Cancelar</Button>
                      <ButtonCadastro type="submit">Cadastrar</ButtonCadastro>
                    </DivButton>
                  </Form>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default DialogAddSchedules
