import { api } from '../../api/axios'

export default new (class UserApi {
  async getUsers() {
    try {
      const response = await api.get('/user')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserByIdContact(id) {
    try {
      const response = await api.get(`/users-contacts/contact/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createUser(data) {
    try {
      const response = await api.post('/user', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserById(id) {
    try {
      const response = await api.get(`/user/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateUser(id, data) {
    try {
      const response = await api.put(`/user/${id}`, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteUser(id) {
    try {
      const response = await api.delete(`/user/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateImage(id, file) {
    try {
      const body = {
        idUser: id,
        imageUser: file,
      }
      const response = await api.post('/user/alterphoto', body)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getAudios() {
    try {
      const response = await api.get('/saved-audios')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteAudios(id) {
    try {
      const response = await api.delete(`/saved-audios/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async storeAudios(base64, label) {
    try {
      const body = {
        base64_audio: base64,
        label_audio: label,
      }
      const response = api.post('/saved-audios', body)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
