import React, { createContext, useState, useContext } from 'react'
import { jwtDecode } from 'jwt-decode'
import LocalStorage from '../utils/LocalStorage'

const SessionContext = createContext()

export const SessionProvider = ({ children, socket }) => {
  const [sessionMedia, setSessionMedia] = useState(null)
  const [sessionName, setSessionName] = useState(null)
  const [sessionStatus, setSessionStatus] = useState(null)
  const [sessionImageFilename, setSessionImageFilename] = useState(null)
  const [sessionImageFiletype, setSessionImageFiletype] = useState(null)

  const verifyIsRoot = () => {
    const token = LocalStorage.getValue('token')
    if (!token) return
    const decoded = jwtDecode(token)
    return decoded.isRoot
  }

  const getSessionData = () => {
    const token = LocalStorage.getValue('token')
    if (!token) return
    const decoded = jwtDecode(token)
    return decoded
  }

  return (
    <SessionContext.Provider
      value={{
        sessionMedia,
        setSessionMedia,
        sessionName,
        setSessionName,
        sessionStatus,
        setSessionStatus,
        sessionImageFilename,
        setSessionImageFilename,
        sessionImageFiletype,
        setSessionImageFiletype,
        verifyIsRoot,
        getSessionData,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => {
  const context = useContext(SessionContext)
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider')
  }
  return context
}
