import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
`

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.MainScreen};
  color: ${({ theme }) => theme.colors.Text};
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Primary};
  padding-bottom: 10px;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
  transition: 0.3s all;

  &:hover {
    color: ${({ theme }) => theme.colors.TextHover};
  }
`

export const ModalBody = styled.div`
  padding: 20px;
  text-align: center;
  border: 2px dashed ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.Primary};
`

export const UploadButton = styled.button`
  background: ${({ theme }) => theme.colors.Button};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }

  &.send {
    margin-right: 10px;
  }
`

export const ModalImage = styled.img`
  src: ${(props) => props.src};
  alt: ${(props) => props.alt};
  max-width: 50%;
`
