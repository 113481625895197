import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  border-radius: 10px;
  @media (max-width: 800px) {
    display: ${({ $navigation }) =>
      $navigation === 'configList' ? 'block' : 'none'};
    width: 100vw;
  }
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80%;
  overflow: auto;
`
