import {
  Container,
  HeaderConfig,
  BottomConfig,
  ImageContact,
  IconsEnd,
  PopoverContent,
  PopoverArrow,
  Item,
  FunnelSimpleStyled,
  FilterContainer,
  TitleFilter,
  TagAbbreviation,
  TextAbbreviation,
  XCircleStyled,
  BottomConfigSup,
  SelectedTagsContainer,
  ClearFilters,
  ContainerTags,
} from './styles'
import Input from '../../../../../../shared/components/Input'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import * as Popover from '@radix-ui/react-popover'
import LoginService from '../../../../../../shared/services/Login/LoginService'
import TagsService from '../../../../../../shared/services/Tags/TagsService'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../../../../../../hook/session'
import { useEffect, useState } from 'react'
import { getTextColor } from '../../../../../../utils/format'

const SearchMenu = ({ setSearchTerm, selectedTags, setSelectedTags }) => {
  const navigate = useNavigate()
  const { sessionMedia } = useSession()
  const [tags, setTags] = useState([])
  const handleLogout = () => {
    LoginService.logout()
  }

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    const getTags = async () => {
      const response = await TagsService.getTags()
      setTags(response.data)
    }
    getTags()
  }, [])

  const renderTags = () => {
    return tags
      ?.filter((tag) => !selectedTags.includes(tag))
      .map((tag) => (
        <TagAbbreviation
          key={tag.id_tag}
          $color={tag.color_hex_tag}
          onClick={() => handleTagClick(tag)}
        >
          <TextAbbreviation $color={() => getTextColor(tag.color_hex_tag)}>
            {tag.abbreviation_tag}
          </TextAbbreviation>
        </TagAbbreviation>
      ))
  }

  const renderSelectedTags = () => {
    return selectedTags.map((tag) => (
      <TagAbbreviation
        key={tag.id_tag}
        $color={tag.color_hex_tag}
        $selected={selectedTags.includes(tag)}
        onClick={() => handleTagClick(tag)}
      >
        <TextAbbreviation
          $color={() => getTextColor(tag.color_hex_tag)}
          $selected={selectedTags.includes(tag)}
        >
          {tag.abbreviation_tag}
        </TextAbbreviation>
        <XCircleStyled $color={() => getTextColor(tag.color_hex_tag)} />
      </TagAbbreviation>
    ))
  }

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((item) => item !== tag))
    } else {
      setSelectedTags([...selectedTags, tag])
    }
  }

  return (
    <Container>
      <HeaderConfig>
        <ImageContact src={sessionMedia} />
        <IconsEnd>
          <SettingsIcon
            fontSize="large"
            style={{ color: 'white', marginLeft: '10px' }}
            cursor="pointer"
            onClick={() => navigate('/settings')}
          />
          <LogoutIcon
            fontSize="large"
            style={{ color: 'white', marginLeft: '10px' }}
            cursor="pointer"
            onClick={handleLogout}
          />
        </IconsEnd>
      </HeaderConfig>
      <BottomConfig>
        <BottomConfigSup>
          <Input
            placeholder="Pesquisar"
            style={{ marginLeft: '5px', marginTop: '5px' }}
            onChange={handleInputChange}
          />
          <Popover.Root>
            <Popover.Trigger asChild>
              <FunnelSimpleStyled />
            </Popover.Trigger>
            <Popover.Portal>
              <PopoverContent className="PopoverContent" sideOffset={5}>
                <FilterContainer>
                  <TitleFilter>Filtrar por:</TitleFilter>
                  <ContainerTags>{renderTags()}</ContainerTags>
                  {selectedTags.length > 0 ? (
                    <Item onClick={() => setSelectedTags([])}>
                      <ClearFilters>Limpar filtros</ClearFilters>
                    </Item>
                  ) : (
                    <p>Nenhuma tag cadastrada!</p>
                  )}
                </FilterContainer>
                <PopoverArrow className="PopoverArrow" />
              </PopoverContent>
            </Popover.Portal>
          </Popover.Root>
        </BottomConfigSup>
        <SelectedTagsContainer>{renderSelectedTags()}</SelectedTagsContainer>
      </BottomConfig>
    </Container>
  )
}

export default SearchMenu
