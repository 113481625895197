import {
  Container,
  HeaderConfig,
  BottomConfig,
  ImageContact,
  IconsEnd,
} from './styles'
import Input from '../../../../../../shared/components/Input'
import ChatIcon from '@mui/icons-material/Chat'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginService from '../../../../../../shared/services/Login/LoginService'
import LogoJit from '../../../../../../assets/images/logojit.jpg'
import { useSession } from '../../../../../../hook/session'

const SearchMenu = ({ setSearchTerm }) => {
  const { sessionMedia } = useSession()

  const handleLogout = () => {
    LoginService.logout()
  }

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleButtonClick = () => {
    window.location.href = '/'
  }

  return (
    <Container>
      <HeaderConfig>
        <ImageContact src={sessionMedia || LogoJit} />
        <IconsEnd>
          <ChatIcon
            fontSize="large"
            style={{ color: 'white', marginLeft: '10px' }}
            cursor="pointer"
            onClick={handleButtonClick}
          />
          <LogoutIcon
            fontSize="large"
            style={{ color: 'white', marginLeft: '10px' }}
            cursor="pointer"
            onClick={handleLogout}
          />
        </IconsEnd>
      </HeaderConfig>
      <BottomConfig>
        <Input placeholder="Pesquisar" onChange={handleInputChange} />
      </BottomConfig>
    </Container>
  )
}

export default SearchMenu
