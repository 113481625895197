import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  border-radius: 10px;
  min-width: 300px;

  @media (max-width: 800px) {
    display: ${({ $navigation }) =>
      $navigation === 'contacts' ? 'block' : 'none'};
    width: 100vw;
  }
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 85vh;
  overflow: auto;
`

export const ErrorMessage = styled.p`
  padding: 1rem;
  font-size: large;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.Text};
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 85vh;
  max-height: 85vh;
  overflow: auto;
`
