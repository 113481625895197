import {
  Container,
  ContactContainer,
  ErrorMessage,
  LoadingContainer,
} from './styles'
import ContactLateral from './components/ContactLateral'
import SearchMenu from './components/SearchMenu'
import imageContato from '../../../../assets/images/imageContato'
import { format, formatMessage, getMessages } from '../../../../utils/format'
import { useCallback, useEffect, useState } from 'react'
import { useImage } from '../../../../hook/image'
import LoadingSpinner from '../../../../shared/components/LoadingSpinner'
import FileService from '../../../../shared/services/File/FileService'
const MAX_LAST_MESSAGE_LENGTH = 25
const MenuLateral = ({
  socket,
  setMessages,
  selectedContact,
  setSelectedContact,
  lateralContacts,
  setLateralContacts,
  navigation,
  setNavigation,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { setContactImage } = useImage()

  const getBase64Media = async (fileName, fileType) => {
    try {
      const response = await FileService.getSrc(fileName, fileType)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  const handleLateralContacts = useCallback(
    async (data) => {
      const formattedData = await format(data, getBase64Media)
      formattedData.forEach((element) => {
        setContactImage(element.id, element.image)
      })
      setLateralContacts(formattedData)
      setIsLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socket, setLateralContacts, setSelectedContact, setMessages],
  )

  useEffect(() => {
    socket.on('lateralContacts', handleLateralContacts)
    return () => {
      socket.off('lateralContacts', handleLateralContacts)
    }
  }, [socket, handleLateralContacts])

  const handleContactClick = (contactId) => {
    setSelectedContact(contactId)
    socket.emit(
      'last100messages',
      { idContact: contactId },
      (err, response) => {
        if (!err) {
          const msg = getMessages(response)

          const lastMessage = msg.length > 0 ? msg[msg.length - 1].message : ''
          const typeMessage =
            msg.length > 0 ? msg[msg.length - 1].typeMessage : ''

          setLateralContacts((prev) =>
            prev.map((contact) =>
              contact.id === contactId
                ? {
                    ...contact,
                    isViewedMessage: true,
                    lastMessage,
                    unreadMessageCount: 0,
                    typeMessage,
                  }
                : contact,
            ),
          )

          setMessages((prev) => ({
            ...prev,
            [contactId]: msg,
          }))
        }
      },
    )
  }

  const filteredContacts = lateralContacts.filter((contact) => {
    const filteredBySearch = contact.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    const filteredByTags = selectedTags.every((tag) =>
      contact.tags?.some((contactTag) => contactTag.id_tag === tag.id_tag),
    )

    return filteredBySearch && filteredByTags
  })

  return (
    <>
      <Container $navigation={navigation}>
        <SearchMenu
          setSearchTerm={setSearchTerm}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <ContactContainer>
          {isLoading ? (
            <LoadingContainer>
              <LoadingSpinner strokeColor="white" />
            </LoadingContainer>
          ) : filteredContacts.length === 0 ? (
            <ErrorMessage>Nenhum contato encontrado!</ErrorMessage>
          ) : (
            filteredContacts.map((contact, index) => {
              if (contact.image === `data:image/png;base64, null`) {
                contact.image = imageContato
              }
              const lastMessage = contact.lastMessage || ''
              const limitedLastMessage =
                lastMessage.length > MAX_LAST_MESSAGE_LENGTH
                  ? formatMessage(lastMessage).substring(
                      0,
                      MAX_LAST_MESSAGE_LENGTH,
                    ) + '...'
                  : formatMessage(lastMessage)
              const typeMessage = contact.typeMessage || ''

              return (
                <ContactLateral
                  socket={socket}
                  key={index}
                  contact={contact}
                  typeMessage={typeMessage}
                  lastMessage={limitedLastMessage}
                  onContactClick={() => {
                    handleContactClick(contact.id)
                    setNavigation('chat')
                  }}
                  selectedContact={selectedContact}
                />
              )
            })
          )}
        </ContactContainer>
      </Container>
    </>
  )
}

export default MenuLateral
