import React, { useEffect, useState } from 'react'
import {
  Container,
  ContactImage,
  DivColumn,
  DivRow,
  NameContact,
  IconsEnd,
  ModalImage,
  EditButton,
  ModalImageSection,
  ModalNameSection,
  ModalNumberSection,
  InputBox,
  InputLabel,
  MoreVertIconStyled,
  CaretContainer,
  CaretLeftStyled,
  UserListStyled,
  TagStyled,
  SyncButton,
  ErrorMessage,
} from './styles'

import Dialog from '../../../../../../shared/components/Dialog'
import imageContato from '../../../../../../assets/images/imageContato'
import { extractImage } from '../../../../../../utils/format'
import DialogUsers from './components/DialogUsers'
import DialogTags from './components/DialogTags'
import ContactService from '../../../../../../shared/services/Contact/ContactService'
import FileServcie from '../../../../../../shared/services/File/FileService'
import { useImage } from '../../../../../../hook/image'

const ContactSuperior = ({
  socket,
  dataContact,
  isModalOpen,
  setNavigation,
  lateralContacts,
  setLateralContacts,
}) => {
  const [modalTagsOpen, setModalTagsOpen] = useState(false)
  const [modalUsersOpen, setModalUsersOpen] = useState(false)
  const [isModalOpenLocal, setIsModalOpenLocal] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [editedNames, setEditedNames] = useState({
    [dataContact.id]: dataContact.name,
  })
  const [error, setError] = useState('')
  const { contactImage, setContactImage } = useImage()

  useEffect(() => {
    if (dataContact && dataContact.name) {
      setEditedNames((prevNames) => ({
        ...prevNames,
        [dataContact.id]: dataContact.name,
      }))
    }
  }, [dataContact])

  useEffect(() => {
    if (!isModalOpen || !isModalOpenLocal) {
      setIsEditOpen(false)
      setError('')
    }
  }, [isModalOpen, isModalOpenLocal])

  const handleOpenModal = () => {
    setIsModalOpenLocal(true)
  }

  const handleSyncImage = async () => {
    try {
      const response = await ContactService.syncImageUser(dataContact.id)
      const filename = response.data.picture_filename_contact
      const filetype = response.data.picture_filetype_contact
      const resp = await FileServcie.getSrc(filename, filetype)
      setContactImage(dataContact.id, resp.data.base64)
    } catch (error) {
      console.log(error)
    }
  }

  const editContact = () => {
    setIsEditOpen(!isEditOpen)
    setError('')
  }

  const handleNameChange = (event) => {
    const newName = event.target.value
    setEditedNames((prevNames) => ({ ...prevNames, [dataContact.id]: newName }))
  }

  const handleSave = () => {
    const newName = editedNames[dataContact.id]
    if (!newName.trim()) {
      setError('O nome não pode estar vazio.')
      return
    }
    setError('')
    setIsEditOpen(false)
    socket.emit('editContact', { id: dataContact.id, newName })
  }

  return (
    <Container>
      <DivRow>
        <CaretContainer onClick={() => setNavigation('contacts')}>
          <CaretLeftStyled weight="bold" />
        </CaretContainer>
        {extractImage(contactImage[dataContact.id]) !== 'null' ? (
          <ContactImage
            src={contactImage[dataContact.id]}
            alt="Imagem do contato"
          />
        ) : (
          <ContactImage src={imageContato} alt="Imagem do contato" />
        )}
        <DivColumn>
          <NameContact>
            {editedNames[dataContact.id] ?? dataContact.name}
          </NameContact>
        </DivColumn>
      </DivRow>
      <IconsEnd>
        <TagStyled onClick={() => setModalTagsOpen(true)} />
        <UserListStyled onClick={() => setModalUsersOpen(true)} />
        <MoreVertIconStyled onClick={handleOpenModal} />
      </IconsEnd>
      <DialogTags
        id={dataContact.id}
        open={modalTagsOpen}
        onChangeOpen={setModalTagsOpen}
        contact={dataContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
      />
      <DialogUsers
        id={dataContact.id}
        open={modalUsersOpen}
        onChangeOpen={setModalUsersOpen}
        contact={dataContact}
      />
      <Dialog
        open={isModalOpen || isModalOpenLocal}
        onChangeOpen={setIsModalOpenLocal}
      >
        <ModalImageSection>
          {extractImage(contactImage[dataContact.id]) !== 'null' ? (
            <ModalImage
              src={contactImage[dataContact.id]}
              alt="Imagem do contato"
            />
          ) : (
            <ModalImage src={imageContato} alt="Imagem do contato" />
          )}
        </ModalImageSection>
        <ModalNameSection>
          {isEditOpen ? (
            <>
              <SyncButton onClick={handleSyncImage}>
                Sincronizar Imagem
              </SyncButton>
              <InputLabel>Nome</InputLabel>
              <InputBox
                maxLength={20}
                value={editedNames[dataContact.id]}
                onChange={handleNameChange}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          ) : (
            <DivColumn>
              <NameContact>
                {editedNames[dataContact.id] ||
                  dataContact.name ||
                  'Nome Indisponível'}
              </NameContact>
            </DivColumn>
          )}
        </ModalNameSection>
        <ModalNumberSection>{dataContact.number}</ModalNumberSection>
        <EditButton onClick={isEditOpen ? handleSave : editContact}>
          {isEditOpen ? 'Salvar' : 'Editar'}
        </EditButton>
      </Dialog>
    </Container>
  )
}

export default ContactSuperior
