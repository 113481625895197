import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M17.295 17.991l-5.678-5.677a4.637 4.637 0 01-1.522.89c-.58.209-1.18.313-1.801.313-1.486 0-2.743-.514-3.772-1.542-1.03-1.029-1.544-2.276-1.544-3.741 0-1.465.515-2.713 1.543-3.742 1.028-1.03 2.277-1.544 3.745-1.544 1.469 0 2.718.514 3.746 1.543 1.03 1.029 1.544 2.276 1.544 3.742A5.228 5.228 0 0112.33 11.6l5.69 5.656-.726.735zM8.28 12.524c1.195 0 2.207-.416 3.037-1.25.83-.832 1.245-1.846 1.245-3.041 0-1.196-.415-2.21-1.245-3.043-.83-.833-1.842-1.25-3.037-1.25-1.203 0-2.222.417-3.057 1.25S3.97 7.037 3.97 8.233c0 1.195.418 2.209 1.253 3.042.835.833 1.854 1.25 3.057 1.25z"
        fill="#777680"
      />
    </svg>
  )
}

export default SvgComponent
