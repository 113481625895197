import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem;
  width: 100%;
`

export const InputColor = styled.input`
  border: none;
  cursor: pointer;
`

export const PColor = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
  display: flex;
  align-items: center;
  justify-content: center;
`
