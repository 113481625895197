import React, { useEffect, useState } from 'react'
import * as S from './styles'
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from './components/DropdownComponents/index'
import IconSearch from '../../../assets/svg/icon_search.jsx'

const Dropdown = ({
  name,
  items,
  handleClick,
  selectedValue,
  $label,
  $noFound,
  $LeftIcon,
  $width,
  $onBlur,
  setSelectedValue,
}) => {
  const [selected, setSelected] = React.useState(null)
  const [valueFlag, setValueFlag] = React.useState(!!selectedValue)
  const [searchValue, setSearchValue] = React.useState('')
  const [openState, setOpenState] = useState('closed')
  const toggleDropdown = (value) => {
    if (openState === 'closed') {
      setOpenState(value)
    } else {
      setOpenState('closed')
    }
  }

  const onClickDropdown = (value) => {
    if (selected === value) {
      setSelected(null)
      $onBlur(name, value)
      setSelectedValue('')
    } else {
      handleClick(value)
      setSelected(value)
      $onBlur(value)
      setOpenState('closed')
      setSelectedValue(value.value)
    }
  }

  useEffect(() => {
    const item = items.find((i) => i.value === selectedValue)
    if (valueFlag) {
      setSelected(item)
      setValueFlag(false)
      setSelectedValue(item?.value)
    }
    if (item) {
      setSelected(item)
      setSelectedValue(item.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedValue, name, valueFlag])

  return (
    <AccordionRoot $value={openState} $onChange={toggleDropdown}>
      <AccordionItem value="buttonExit" $width={$width}>
        <AccordionHeader>
          <AccordionTrigger>
            {$LeftIcon}
            <p>{selected?.label ?? `${$label}`}</p>
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent>
          <S.SectionSearch>
            <S.Input
              value={searchValue || ''}
              onChange={(e) => setSearchValue(e.target.value)}
              type="text"
              placeholder={'Pesquisar'}
            />
            <IconSearch />
          </S.SectionSearch>
          <S.ScrollArea $height="7.375rem">
            {(function (filterItems) {
              if (filterItems.length === 0)
                return <div className="noFoundCategory">{$noFound}</div>
              return filterItems.map((item) => (
                <S.Item key={item.value} onClick={() => onClickDropdown(item)}>
                  <S.ItemParagraph
                    className={selected === item ? 'selected' : ''}
                  >
                    {item.label}
                    {selected === item && <S.XCircleStyled size={16} />}
                  </S.ItemParagraph>
                </S.Item>
              ))
            })(
              items.filter((item) =>
                item?.label?.toLowerCase().includes(searchValue.toLowerCase()),
              ),
            )}
          </S.ScrollArea>
        </AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  )
}

export default Dropdown
