import React, { useEffect, useState } from 'react'
import {
  ContactNotFound,
  Container,
  DivRowSupContacts,
  Header,
  LeftContainer,
  ListUsers,
  StyledPencil,
  StyledPlusCircle,
  StyledTrash,
  Subtitle,
  Title,
} from '../Gerenciamento/styles'
import Input from '../../../../../../shared/components/Input'
import TagsService from '../../../../../../shared/services/Tags/TagsService'
import {
  Tag,
  TagAbbreviation,
  TagActions,
  TagText,
  TextAbbreviation,
} from './styles'
import { useToast } from '../../../../../../hook/toast'
import DialogTags from './components/DialogTags'
import { getTextColor } from '../../../../../../utils/format'

const Tags = () => {
  const { addToast } = useToast()
  const [searchTerm, setSearchTerm] = useState('')
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    const getTags = async () => {
      const response = await TagsService.getTags()
      setTags(response.data)
    }
    getTags()
  }, [update])

  const filteredTags = () => {
    if (searchTerm) {
      return tags.filter((tag) => {
        const name = tag.name_tag.toLowerCase()
        const abbreviation = tag.abbreviation_tag.toLowerCase()
        const term = searchTerm.toLowerCase()
        if (tags.length === 0) return false
        return name.includes(term) || abbreviation.includes(term)
      })
    } else {
      return tags
    }
  }

  const handleDeleteTag = async (tag) => {
    const response = await TagsService.deleteTag(tag.id_tag)
    if (response.status === 204) {
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'A tag foi deletada com sucesso',
      })
      setTags(tags.filter((t) => t.id_tag !== tag.id_tag))
    } else {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Erro ao deletar tag',
      })
    }
  }

  const handleCreateTag = () => {
    setSelectedTag(null)
    setOpenDialog(true)
  }

  const handleEditTag = async (tag) => {
    setSelectedTag(tag)
    setOpenDialog(true)
  }

  return (
    <Container>
      <LeftContainer>
        <Header>
          <Title>Tags</Title>
          <Subtitle>Gerenciamento de Tags</Subtitle>
        </Header>
        <ListUsers $isMobileFullScreen={true}>
          <DivRowSupContacts>
            <Input
              placeholder="Pesquisar usuários"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <StyledPlusCircle size={24} onClick={() => handleCreateTag()} />
            <DialogTags
              open={openDialog}
              onChangeOpen={setOpenDialog}
              setTags={setTags}
              update={update}
              setUpdate={setUpdate}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
            />
          </DivRowSupContacts>
          {tags.length > 0 ? (
            filteredTags().map((tag) => (
              <Tag key={tag.id_tag}>
                <TagActions>
                  <TagText>{tag.name_tag}</TagText>
                  <TagText>-</TagText>
                  <TagAbbreviation $color={tag.color_hex_tag}>
                    <TextAbbreviation
                      $color={() => getTextColor(tag.color_hex_tag)}
                    >
                      {tag.abbreviation_tag}
                    </TextAbbreviation>
                  </TagAbbreviation>
                </TagActions>
                <TagActions>
                  <StyledPencil size={24} onClick={() => handleEditTag(tag)} />
                  <StyledTrash size={24} onClick={() => handleDeleteTag(tag)} />
                </TagActions>
              </Tag>
            ))
          ) : (
            <ContactNotFound>Nenhuma tag encontrada</ContactNotFound>
          )}
        </ListUsers>
      </LeftContainer>
    </Container>
  )
}

export default Tags
