import { BrowserRouter } from 'react-router-dom'
import Rotas from './routes/index'
import GlobalStyles from './styles/global'
import { ThemeProvider } from './hook/theme'
import { SessionProvider } from './hook/session'

function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <SessionProvider>
          <ThemeProvider>
            <Rotas />
          </ThemeProvider>
        </SessionProvider>
      </BrowserRouter>
    </>
  )
}

export default App
