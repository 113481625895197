import styled from 'styled-components'
import { Send } from '@mui/icons-material'
import {
  Note,
  Microphone,
  Smiley,
  Article,
  Trash,
  Paperclip,
} from 'phosphor-react'

export const ContainerCollumContent = styled.div`
  flex: 1;
  width: 30vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media (max-width: 800px) {
    display: ${({ $navigation }) =>
      $navigation === 'chat' ? 'block' : 'none'};
  }
`

export const MessagesBox = styled.div`
  display: flex;
  width: 100%;
  max-height: 80vh;
  min-height: 80vh;
  background-color: ${({ theme }) => theme.colors.BackgroundMessage};
  align-items: center;
  justify-content: flex-end;
  scroll-behavior: smooth;
  flex-direction: column;
  overflow-y: auto;
`

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
`

export const NoMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const NoMessageP = styled.p`
  color: ${({ theme }) => theme.colors.Text};
  font-size: 0.8rem;
  margin-top: 1rem;
  font-weight: 500;
`

export const NoMessageH1 = styled.h1`
  color: ${({ theme }) => theme.colors.Text};
  font-size: 2rem;
  font-weight: 600;
`

export const SendMessages = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.Primary};
  height: 10vh;
  @media (max-width: 800px) {
    padding: 1rem 0rem;
  }
`

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  gap: 3px;
`

export const ContainerPreview = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 3rem;
  width: 100%;
`

export const DragBox = styled.div`
  width: 100%;
  height: 200px; /* Altura fixa para acomodar a imagem */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${({ theme }) => theme.colors.Primary};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  margin-bottom: 1rem;
  position: relative;
`

export const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Outline};
`

export const DragDropArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${({ theme }) => theme.colors.Outline};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  margin-bottom: 1rem;
`

export const AttachFileIconStyled = styled(Paperclip)`
  color: ${({ theme }) => theme.colors.Text};
  rotate: -45deg;
  @media (max-width: 800px) {
    width: 25px;
  }
`

export const SendStyled = styled(Send)`
  color: ${({ theme }) => theme.colors.Text};
  z-index: 10;
  cursor: pointer;
  @media (max-width: 800px) {
    width: 20px;
  }
`

export const StyledTrash = styled(Trash)`
  color: ${({ theme }) => theme.colors.Alert};
  z-index: 10;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
`

export const NoteStyled = styled(Note)`
  color: ${({ theme, $isNote }) =>
    $isNote ? theme.colors.Note : theme.colors.Text};
  width: 40px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 800px) {
    width: 25px;
  }
`

export const MicrophoneStyled = styled(Microphone)`
  color: ${({ theme }) => theme.colors.Text};
  align-self: center;
  border-radius: 50%;
  height: 30px;
  width: 33px;
  padding: 0.1rem;
  background-color: ${({ $isRecording, theme }) =>
    $isRecording ? theme.colors.Alert : null};
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.5s ease;
  margin-right: 10px;
  @media (max-width: 800px) {
    width: 28px;
    height: 25px;
    margin-right: 0px;
  }
`

export const ContainerRec = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RecTime = styled.span`
  position: absolute;
  margin-top: 50px;
  margin-right: 9px;
  color: ${({ theme }) => theme.colors.Text};
  font-size: small;
  align-self: center;
  transition: display 0.5s ease;
  display: ${({ $isRecording }) => ($isRecording ? 'block' : 'none')};
  @media (max-width: 800px) {
    margin-top: 45px;
    margin-right: 0px;
  }
`

export const EmojiStyled = styled(Smiley)`
  color: ${({ theme, $isNote }) =>
    $isNote ? theme.colors.Note : theme.colors.Text};
  width: 40px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 800px) {
    width: 25px;
  }
`

export const AudioMonitor = styled.div`
  color: ${({ theme }) => theme.colors.Text};
`

export const AudioPlayerStyled = styled.audio`
  width: 100%;
  margin: 10px 0;
  outline: none;
  border-radius: 8px;
`

export const SendButtonStyled = styled.button`
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const CancelButtonStyled = styled.button`
  background-color: ${({ theme }) => theme.colors.Alert};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`
export const GalleryFile = styled(Article)`
  color: ${({ theme, $isNote }) =>
    $isNote ? theme.colors.Note : theme.colors.Text};
  width: 40px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 800px) {
    width: 25px;
  }
`

export const ContainerPreviewRec = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 10px;
`

export const ContainerNotSelectedContact = styled.div`
  display: flex;
  width: 70vw;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    display: ${({ $navigation }) =>
      $navigation === 'chat' ? 'block' : 'none'};
  }
`

export const Title = styled.h1`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`
