import styled from 'styled-components'
import { X } from 'phosphor-react'

export const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const DialogContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.MainScreen};
  color: ${({ theme }) => theme.colors.Text};
  padding: 1.5rem 4rem 1rem 4rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 35rem;

  > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: ${({ theme }) => theme.colors.Text};
    &:hover {
      color: ${({ theme }) => theme.colors.TextHover};
    }
  }

  @media (max-width: 768px) {
    width: 90vw;
    padding: 1rem 3rem 1rem 3rem;
  }
`

export const XStyled = styled(X)`
  cursor: pointer;
  transition: color 0.1s ease-in;
  &:hover {
    color: ${({ theme }) => theme.colors.TextHover};
  }
`

export const DialogOverlay = styled.div`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 1000;
`

export const Header = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Subtitle = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const ListContacts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 45vh;
  gap: 8px;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.Primary};
  border-radius: 8px;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const DivRowSupContacts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.colors.BackgroundMessageStrong
      : theme.colors.MainScreen};
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.colors.BackgroundMessageStrong
        : theme.colors.WhiteHover};
  }
`

export const ContactText = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
  padding: 0.1rem;
`

export const DivRowContact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const DivButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: 1rem;
`

export const ButtonCancel = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.Primary};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
  &:hover {
    background-color: ${({ theme }) => theme.colors.WhiteHover};
  }
`

export const ButtonCadastro = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const NoContacts = styled.h2`
  font-size: 1rem;
  color: red;
  padding: 0.1rem;
  align-self: center;
`
