import React, { useState, useEffect } from 'react'
import {
  Container,
  AudioItem,
  AudioPlayer,
  AudioTitle,
  AudioLabel,
  SendAudio,
  Actions,
  AudiosContainer,
  AudioLabelToolTip,
} from '../../../../../../shared/components/AudioLibrary/styles'
import UserService from '../../../../../../shared/services/User/UserService'
import * as Tooltip from '@radix-ui/react-tooltip'
import { TooltipContent, TooltipArrow } from '../Message/styles'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import FileService from '../../../../../../shared/services/File/FileService'
import { MessageNoAudio } from './style'

const AudioLibrary = ({ isOpen, socket, sendMessage, setFile }) => {
  const theme = useTheme()
  const [audios, setAudios] = useState([])
  const [audioSources, setAudioSources] = useState({})
  const [loading, setLoading] = useState(true) // Adicionando estado de carregamento

  useEffect(() => {
    fetchAudios()
    // eslint-disable-next-line
  }, [])

  const getSrc = async (filename, filetype) => {
    try {
      const response = await FileService.getSrc(filename, filetype)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAudios = async () => {
    try {
      const response = await UserService.getAudios()
      setAudios(Array.isArray(response.data) ? response.data : [])

      const sources = await Promise.all(
        response.data.map(async (audio) => {
          const base64 = await getSrc(
            audio.filename_audio,
            audio.filetype_audio,
          )
          return { id: audio.id_users_audios, src: base64 }
        }),
      )

      const newAudioSources = sources.reduce((acc, { id, src }) => {
        acc[id] = src
        return acc
      }, {})

      setAudioSources(newAudioSources)
      setLoading(false) // Marcar o fim do carregamento
    } catch (error) {
      console.log(error)
    }
  }

  if (!isOpen || loading) {
    return (
      <Container>
        <LoadingSpinner strokeColor={theme.colors.Text} />
      </Container>
    )
  }

  return (
    <Container>
      <AudioTitle>Biblioteca de Áudios</AudioTitle>
      {audios.length === 0 ? (
        <MessageNoAudio>Nenhum áudio foi encontrado</MessageNoAudio>
      ) : (
        <AudiosContainer>
          {audios.map((element) => {
            const src = audioSources[element.id_users_audios]
            const type = src?.split(';')[0].split(':')[1]
            return (
              <AudioItem key={element.id_users_audios}>
                {element.label_audio.length >= 14 ? (
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <AudioLabel
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={sendMessage}
                        >
                          {element.label_audio.slice(0, 11)}...
                        </AudioLabel>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <TooltipContent
                          className="TooltipContent"
                          sideOffset={5}
                        >
                          <AudioLabelToolTip onClick={sendMessage}>
                            {element.label_audio}
                          </AudioLabelToolTip>
                          <TooltipArrow />
                        </TooltipContent>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                ) : (
                  <AudioLabel onClick={sendMessage}>
                    {element.label_audio}
                  </AudioLabel>
                )}
                <AudioPlayer
                  controls
                  src={src}
                  type={type}
                  onError={(e) =>
                    console.log('Erro ao carregar o áudio:', e.target)
                  }
                  onCanPlay={() => console.log('Áudio pronto para tocar')}
                />
                <Actions>
                  <SendAudio
                    onClick={() => {
                      setFile(src)
                      sendMessage()
                    }}
                  />
                </Actions>
              </AudioItem>
            )
          })}
        </AudiosContainer>
      )}
    </Container>
  )
}

export default AudioLibrary
