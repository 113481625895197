import ScheduleApi from './api/ScheduleApi'

class SchedulesService {
  async getSchedules() {
    try {
      const response = await ScheduleApi.getSchedules()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createSchedule(data) {
    try {
      const response = await ScheduleApi.createSchedule(data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateSchedule(id, data) {
    try {
      const response = await ScheduleApi.updateSchedule(id, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteSchedule(id) {
    try {
      const response = await ScheduleApi.deleteSchedule(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
}

export default new SchedulesService()
