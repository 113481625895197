import { styled } from 'styled-components'
import { XCircle } from 'phosphor-react'

export const ContainerDialog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  > h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    color: black;
  }
`
export const DialogScreenBody = styled.div``
export const DialogScreenHeader = styled.div`
  > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.Text};
  }
`

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  border: 1px solid black;
  border-radius: 20px 20px 0 0;
  padding: 10px 20px 10px 20px;
  gap: 4rem;
  > p {
    font-weight: 400;
  }
`
export const DialogBox = styled.div`
  border: 1px solid black;
  border-radius: 0 0 20px 20px;
  padding: 20px 0 20px 20px;
  height: 320px;
`

export const SectionSearch = styled.div`
  border-radius: 0.625rem;
  border: 1px solid ${({ theme }) => theme.colors.Outline};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.6rem;
`
export const Input = styled.input`
  border: none;
  background: none;
  font-size: 0.875rem;
  font-family: Manrope;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.Text};
  padding: 0rem 0.6rem;
  outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.Outline};
  }
`
export const Item = styled.div`
  display: flex;
  padding: 0.125rem 0.25rem;
  justify-content: flex-start;
  cursor: pointer;
  gap: 0.375rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease;
  .selected {
    background-color: ${({ theme }) => theme.colors.SelectedContact};
  }
`

export const ItemParagraph = styled.p`
  padding: 2px 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
  background-color: ${({ theme }) => theme.colors.BackgroundMessage};
  transition: all 0.15s ease;
  &:hover {
    background-color: ${({ theme }) => theme.colors.Primary};
  }
`

export const DialogContainerCategory = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  overflow: auto;
  padding-right: 15px;
  position: relative;
  z-index: 0;
`

export const contentBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > label {
    font-weight: 800;
    font-size: 16px;
  }
`
export const contentAuxBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`

export const DialogFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
`

export const Divisor = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
`

export const Line = styled.hr`
  height: 1px;
  width: 100%;
  background-color: black;
  position: relative;
`

export const Text = styled.p`
  margin: 0 10px;
`

export const OuText = styled(Text)`
  position: relative;
  z-index: 1;
`
export const ScrollArea = styled.div`
  overflow: auto;
  max-height: ${(p) => p.$height};
`

export const XCircleStyled = styled(XCircle)`
  color: ${({ theme }) => theme.colors.Text};
`
