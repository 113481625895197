import { useCallback, useEffect } from 'react'
import InputMask from 'react-input-mask'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
} from './styles'
import { ErrorSpan } from '../../../DialogUsers/styles.js'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import Input from '../../../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { formatPhoneNumber } from '../../../../../../../../../../utils/format.js'
import ContactService from '../../../../../../../../../../shared/services/Contact/ContactService.js'
import { useToast } from '../../../../../../../../../../hook/toast.jsx'

const Dialog = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
}) => {
  const { addToast } = useToast()
  const contactScheme = z.object({
    fullNameContact: z.string().min(1),
    idContact: z
      .string()
      .min(19)
      .refine((val) => !val.includes('_'), {
        message: 'O número está incompleto',
      }),
  })

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(contactScheme),
  })

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  const onSubmit = async (data) => {
    data.idContact = formatPhoneNumber(data.idContact)
    const res = await ContactService.storeContact(data)
    if (res.status === 201) {
      addToast({
        type: 'success',
        title: 'Contato cadastrado com sucesso',
        description: 'O contato foi cadastrado com sucesso',
      })
      setUpdate(!update)
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar contato',
        description: 'Ocorreu um erro ao cadastrar o contato',
      })
    }
    handleCloseDialog()
  }

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <Container>
                  <Header>
                    <Title>Criar contato:</Title>
                    <DialogRadix.Close asChild>
                      {showIconClose && (
                        <X
                          onClick={handleCloseDialog}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </DialogRadix.Close>
                  </Header>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Label>Nome:</Label>
                    <Input
                      {...register('fullNameContact')}
                      placeholder="Digite o nome"
                      type="text"
                    />
                    <ErrorSpan $span={!!errors.fullNameContact?.message}>
                      {errors.fullNameContact?.message || 'a'}
                    </ErrorSpan>

                    <Label>Telefone</Label>
                    <InputMask
                      mask="+99 (99) 99999-9999"
                      {...register('idContact')}
                    >
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          placeholder="Digite o telefone"
                          type="text"
                        />
                      )}
                    </InputMask>

                    <ErrorSpan $span={!!errors.idContact?.message}>
                      {errors.idContact?.message || 'a'}
                    </ErrorSpan>

                    <DivButton>
                      <Button onClick={handleCloseDialog}>Cancelar</Button>
                      <ButtonCadastro type="submit">Cadastrar</ButtonCadastro>
                    </DivButton>
                  </Form>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default Dialog
