import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Subtitle = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`

export const ListContentTitle = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const ListContentText = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.Text};
  background-color: ${({ theme }) => theme.colors.Thumb};
  padding: 0.3rem;
  border-radius: 8px;
  font-weight: 400;
`

export const TextAreaStyled = styled.textarea`
  width: 15vw;
  height: 20vh;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.Thumb};
  border: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.Text};
  max-width: 22vw;
  max-height: 70vh;
`

export const ButtonDownload = styled.a`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`
