import React from 'react'
import { BackButton, Container } from './styles.js'
import * as Tabs from '@radix-ui/react-tabs'
import { CaretLeft } from 'phosphor-react'
import Geral from './components/Geral'
import Gerenciamento from './components/Gerenciamento'
import AudioLibraryStore from './components/AudioLibraryStore/index.jsx'
import Tags from './components/Tags/index.jsx'
import AgendamentoMensagens from './components/AgendamentoMensagens/index.jsx'

const MainScreen = ({ tab, setTab, navigation, setNavigation, socket }) => {
  return (
    <Container $navigation={navigation}>
      <BackButton onClick={() => setNavigation('configList')}>
        <CaretLeft size={32} weight="bold" />
      </BackButton>
      <Tabs.Root value={tab} onValueChange={setTab}>
        <Tabs.Content value="1">
          <Geral />
        </Tabs.Content>
        <Tabs.Content value="2">
          <Gerenciamento socket={socket} />
        </Tabs.Content>
        <Tabs.Content value="3">
          <Tags />
        </Tabs.Content>
        <Tabs.Content value="4">
          <AudioLibraryStore />
        </Tabs.Content>
        <Tabs.Content value="5">
          <AgendamentoMensagens />
        </Tabs.Content>
      </Tabs.Root>
    </Container>
  )
}

export default MainScreen
