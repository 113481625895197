import React, { useState } from 'react'
import { Container } from './styles'
import LeftMenu from './components/LeftMenu'
import MainScreen from './components/MainScreen'

const Settings = ({ socket }) => {
  const [navigation, setNavigation] = useState('configList')
  const [tab, setTab] = React.useState('1')
  return (
    <Container>
      <LeftMenu
        tab={tab}
        setTab={setTab}
        navigation={navigation}
        setNavigation={setNavigation}
      />
      <MainScreen
        tab={tab}
        setTab={setTab}
        navigation={navigation}
        setNavigation={setNavigation}
        socket={socket}
      />
    </Container>
  )
}

export default Settings
